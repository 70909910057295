<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0 shadow-box">
        <b-link class="brand-logo">
          <vuexy-logo style="height: 6em;" />
        </b-link>

        <b-card class="bg-light-secondary mb-2 text-center">
          <small>
            A new password will be sent to your registered e-mail address.
          </small>
        </b-card>

        <!-- form -->
        <validation-observer ref="forgetPassValidation" #default="{invalid}">
          <b-form class="auth-login-form mt-2" @submit.prevent>
            <!-- email -->
            <b-overlay :show="show" rounded="sm" opacity="0.2" no-fade>
              <b-form-group label-for="email" label="Email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="userEmail"
                    type="email"
                    name="login-email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="user@citinfo.ie"
                    autofocus
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <template v-slot:overlay>
                <div class="d-flex align-items-center">
                  <b-spinner small type="grow" variant="secondary" />
                  <b-spinner type="grow" variant="dark" />
                  <b-spinner small type="grow" variant="secondary" />
                  <!-- We add an SR only text for screen readers -->
                  <span class="sr-only">Please wait...</span>
                </div>
              </template>

              <!-- submit button -->
              <b-button
                variant="primary"
                type="submit"
                @click="passwordReset"
                block
                :disabled="invalid"
              >
                Reset Password
              </b-button>

              <div
                class="mt-1 mb-1 d-flex align-items-center justify-content-center"
              >
                <router-link to="/login"> I have an account login</router-link>
              </div>
            </b-overlay>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BSpinner,
  BOverlay,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axiosIns from "@/libs/axios";
import router from "@/router";
import useJwt from "@/auth/jwt/useJwt";
import homeStoreModule from "./homeStoreModule";
import { ref, onUnmounted, reactive } from "@vue/composition-api";

export default {
  components: {
    BSpinner,
    BOverlay,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },

  setup() {
    const HOME_APP_STORE_MODULE_NAME = "home";
    // Register module
    if (!store.hasModule(HOME_APP_STORE_MODULE_NAME))
      store.registerModule(HOME_APP_STORE_MODULE_NAME, homeStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HOME_APP_STORE_MODULE_NAME))
        store.unregisterModule(HOME_APP_STORE_MODULE_NAME);
    });
  },
  data() {
    return {
      userEmail: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      show: false,
    };
  },
  methods: {
    passwordReset() {
      this.$refs.forgetPassValidation.validate().then((success) => {
        if (success) {
          this.show = true;
          store
            .dispatch("home/passwordReset", { user: this.userEmail })
            .then((response) => {
              if (response.data.success) {
                this.$swal({
                  title: "Success",
                  text: response.data.message,
                  icon: "success",
                  showCancelButton: false,
                  confirmButtonText: "OK",
                });

                router.push({ path: "/login" });
                this.show = false;
              } else {
                this.$swal({
                  title: "User not found",
                  text: response.data.message,
                  icon: "error",
                  showCancelButton: false,
                  confirmButtonText: "OK",
                });

                this.show = false;
              }
            })
            .catch((error) => {
              this.$swal({
                title: "Error",
                text: "Error occurred, please try again or contact support",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "OK",
              });

              this.show = false;
              this.$refs.forgetPassValidation.setErrors(
                error.response.data.error
              );
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>

<style scoped>
.shadow-box {
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
}
</style>
